import React from 'react';

import { Container } from './styles';


export default function Clientes({ src, ...rest }) {
  return (
    <Container {...rest}>
      <img src={src} />
    </Container>
  );
}
