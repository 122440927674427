import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { FaWhatsapp } from 'react-icons/fa';


import posed from 'react-pose';

import Lottie from 'react-lottie';
import * as airplane from 'src/airplane.json'

import { Container, Info, Title, Button, Airplane } from './styles';


import BackgroundSlide from 'src/components/Images/BackgroundSlide';
import Cloud from 'src/components/Animations/Cloud';
import nuvem_1 from 'src/images/nuvem_1.png';
import nuvem_2 from 'src/images/nuvem_2.png';
import nuvem_3 from 'src/images/nuvem_3.png';

export default function MainSlider() {
  const { t } = useTranslation();
  const Box = posed.div({
    left: { x: -100 },
    right: { x: 100 }
  })

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: airplane.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <Container id="header">

      <Box />

      <Airplane>
        <Lottie options={defaultOptions}

          isStopped={false}
          isPaused={false} />
      </Airplane>
      <Cloud src={nuvem_1} className="cloud cloud_1" />
      <Cloud src={nuvem_2} className="cloud cloud_2" />
      <Cloud src={nuvem_3} className="cloud cloud_3" />
      <Info>

        <Title>{t('main_slider').title}</Title>
        <Button href="https://api.whatsapp.com/send?phone=5521991501712" target="_blank">
          <FaWhatsapp size={30} color="#fff" />
          <span>{t('main_slider').button}</span>
        </Button>
      </Info>
      <BackgroundSlide />

    </Container>
  );
}
