import React, { useState } from 'react';
import { Link } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import SEO from 'src/components/Seo';
import Default from 'src/pages/_layouts/Default';
import MainSlider from 'src/components/MainSlider';
import Content from 'src/components/Content';
import Box from 'src/components/Box';
import ProdutosTabs from 'src/components/ProdutosTabs';
import ProdutosBoxes from 'src/components/ProdutosBoxes';
import Blog from 'src/components/Blog';

import Tartaruga from 'src/components/Animations/Tartaruga';
import Arraia from 'src/components/Animations/Arraia';
import Peixes from 'src/components/Animations/Peixes';
import Clientes from 'src/components/Animations/Clientes';
import Address from 'src/components/Address';
import Contact from 'src/components/Contact';
import Abragames from 'src/components/Abragames';

import clientes_1 from 'src/images/clientes_1.png';
import clientes_2 from 'src/images/clientes_2.png';

import mti_reel from 'src/videos/mti_reel.mp4';

export default function IndexPage() {
  const [product, setProduct] = useState(1);
  const { t } = useTranslation();
  return (
    <Default>
      <SEO title={t('Home')} />
      <MainSlider />

      <Content>
        <Box data-sal="zoom-in" titleColor="#F3901E" id="quem-somos">
          <h2>{t('who_whe_are').title}</h2>
          <div dangerouslySetInnerHTML={{ __html: t('who_whe_are').body }} />
        </Box>
        <Tartaruga data-sal="zoom-in" className="hide-mobile" />
        <div className="divider"></div>
        <Arraia data-sal="zoom-in" className="hide-mobile" />
        <Box data-sal="zoom-in" titleColor="#BA225C" id="atuacao">
          <h2>{t('expertise_areas').title}</h2>
          <div
            dangerouslySetInnerHTML={{ __html: t('expertise_areas').body }}
          />
        </Box>
        <div className="divider"></div>

        <Box
          data-sal="zoom-in"
          titleColor="#031A42"
          id="portfolio"
          className="video"
        >
          <h2>{t('portfolio').title}</h2>
          <video
            title="Video"
            width="100%"
            height="315"
            src={mti_reel}
            controls="controls"
          />
          <Link to="/portfolio" className="btn btn-primary">
            {t('portfolio').button}
          </Link>
        </Box>
        <Peixes data-sal="zoom-in" className="hide-mobile" />
        <div className="divider"></div>
        <Box data-sal="zoom-in" titleColor="#28C976" id="produtos">
          <h2>Produtos</h2>
          <ProdutosTabs activeFunction={setProduct} />
        </Box>
        <ProdutosBoxes active={product} data-sal="zoom-in" />
        <div className="divider"></div>

        <Clientes data-sal="zoom-in" id="clientes" src={clientes_1} />
        <Clientes data-sal="zoom-in" id="clientes_2" src={clientes_2} />
        <div className="divider"></div>
        <Address />
        <Contact id="contato" />
        <Abragames />
      </Content>
    </Default>
  );
}
