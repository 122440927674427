import styled from 'styled-components';

export const Container = styled.div`
  z-index: 1;
  min-width: 414px;

  @media only screen and (max-width: 900px) {
    max-width: 100%;
    min-width: 100%;
    margin-bottom: 30px;
  }
  
  h3{
    color: #EBA203;
    margin-top: 20px;
    font-size: 32px;
  }
  p{
    color: #fff;
    margin-bottom: 10px;
    display: flex;
    align-content: center;

    span{
      margin-left: 10px;
    }
  }
`;
