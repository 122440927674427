import React from 'react';

import { Container } from './styles';
import tartaruga from 'src/images/tartaruga.png';

export default function Tartaruga({ ...rest }) {
  return (
    <Container {...rest}>
      <img src={tartaruga} width="400px" />
    </Container>
  );
}
