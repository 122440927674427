import React from 'react';
import 'react-tabs/style/react-tabs.css';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { TabC, TabsC, TabListC, TabPanelC } from './styles';

import flupe from 'src/images/moobing-banner.png';
import wegame from 'src/images/web-game-banner.png';

export default function ProdutosTabs({ activeFunction }) {

  const { t } = useTranslation();

  return (
    <TabsC>
      <TabListC>
        <TabC onClick={() => activeFunction(1)}>{t('moobing_tab').title}</TabC>
        <TabC onClick={() => activeFunction(2)}>{t('wowlgame_tab').title}</TabC>
      </TabListC>
      <TabPanelC>
        <img src={flupe} alt={t('moobing_tab').title} />
        <div dangerouslySetInnerHTML={{ __html: t('moobing_tab').body }} />
        <a href="https://flupe.com.br/" target="_blank" rel="noopener noreferrer" className="btn btn-primary">{t('moobing_tab').button}</a>
      </TabPanelC>
      <TabPanelC>
        <img src={wegame} alt={t('wowlgame_tab').title} />
        <div dangerouslySetInnerHTML={{ __html: t('wowlgame_tab').body }} />
        <a href="https://play.google.com/store/apps/details?id=com.mtistudio.wowl&hl=pt_BR " target="_blank" rel="noopener noreferrer" className="btn btn-primary">{t('wowlgame_tab').button}</a>
      </TabPanelC>
    </TabsC>
  );
}
