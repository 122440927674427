import styled from 'styled-components';

export const Container = styled.div`
 
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 70px;
  margin-top: 70px;

   img{
     width: 200px;
   }
`;
