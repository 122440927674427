import React from 'react';


import { Container, Content, Title } from './styles';

import Flupe from 'src/images/tela_moobing.png';
import wegame from 'src/images/tela_we_game.png';

export default function ProdutosBoxes({ active, ...rest }) {
  return (
    <Container {...rest}>
      <Content active={active === 1}>
        <Title>Flupe</Title>
        <img src={Flupe} alt="Flupe" />
      </Content>
      <Content active={active === 2}>
        <Title>WOWL Game</Title>
        <img src={wegame} alt="WOWL Game" />
      </Content>
    </Container>
  );
}
