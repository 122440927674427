import styled from 'styled-components';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

export const TabC = styled(Tab)`
  margin-top: 0px!important;
  flex: 1;
  text-align: center;
  font-size: 18px;
  padding: 20px;

  background: #039344;
  color: #BBFCD5;
  border-radius: 0;

  cursor: pointer;

  
`;

export const TabsC = styled(Tabs)`
  
`;

export const TabListC = styled(TabList)`
  display: flex;
  list-style-type: none!important;
  margin: 0px!important;
  margin-left: -30px!important;
  margin-right: -30px!important;

  .react-tabs__tab--selected{
    background: #fff;
    color: #575756;
  }
  
  
`;

export const TabPanelC = styled(TabPanel)`
  img{
    margin-top: 30px;
  }
  a{
    margin: 0 60px;
    margin-top: 20px;
    border-radius: 10px;
    background: #28C976;
  }
`;