import styled from 'styled-components';

export const Container = styled.div`
  z-index: 1;
  width: 414px;
  h3{
    color: #eba203;
    margin-bottom: 20px; 
  }
  form{
    display: flex;
    flex-direction: column;
    ;

    label{
      width: 100%;
      color: #fff;
      font-weight: bold;


      input, textarea{
        margin-top: 5px;
        margin-bottom: 10px; 
        width: 100%;
        border: 0;
        padding: 10px;
      } 
    }
    button{
      background: #eba203;
      font-weight: bold;
      color: #fff;
      padding: 10px;
      border: none;
    }

    
  }
  
`;
