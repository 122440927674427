import styled from 'styled-components';


export const Container = styled.div`
  max-width: 414px;
  z-index: 10;
  
`;

export const Content = styled.div`
  display: ${props => props.active ? 'block' : 'none'};
  
  img {
    box-shadow: 0 30px 30px rgba(0, 0, 0, 0.4);
    background: #fff;
    border-radius: 10px;
  }
`;

export const Title = styled.h3`
  background: #039344;
  color: #fff;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0 30px 30px rgba(0, 0, 0, 0.4);
`;

