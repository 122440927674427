import React from 'react';

import { Container } from './styles';
import peixes from 'src/images/peixes.png';

export default function Peixes({ ...rest }) {
  return (
    <Container {...rest}>
      <img src={peixes} width="400px" />
    </Container>
  );
}
