import styled from 'styled-components';

export const Container = styled.div`
  
  max-width: 414px;
  min-width: 414px;
  background: #fff;
  border-radius: 10px;
  padding: 30px;
  z-index: 10;
  box-shadow: 0 30px 30px rgba(0, 0, 0, 0.4);

  @media only screen and (max-width: 900px) {
    max-width: 100%;

    min-width: 100%;
    margin-bottom: 30px;
  }

  h2{
    background: ${props => props.titleColor};
    color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 20px;
    text-align: center;
    margin: 0 -30px;
    margin-top: -30px;
  }

  p{
    margin-top: 30px;
    font-size: 16px;
  }
  p+p{
    margin-top: 10px;
  }

  ul{
    margin: 30px;
    margin-bottom: 0px;
    font-size: 16px;
    list-style-type: disc;
    
    li + li{
      margin-top: 10px;
    }
  }

  &.video{
    padding: 0;
    overflow: hidden;

    h2{
      margin: 0;
    }
  }

  a{
    margin: 20px 80px;
    border-radius: 10px;
  }
`;
