import styled from 'styled-components';


export const Container = styled.div`
  max-width: 700px;
  z-index: 10;
  
`;


export const Title = styled.h2`
  
  color: #fff;
  margin-top:100px;
  margin-bottom:110px;
  
`;

