import styled, { keyframes } from 'styled-components';

const airplane = keyframes`
  0% {
      left: -50%;
  }
  100% {
      left: 150%;
  }
`;

export const Container = styled.div`
  position: relative;
  z-index: 10;
  margin-bottom: -50px;
  padding-top: 70px;

  max-width: 100%;
  overflow: hidden;
  
  .cloud{
    position: absolute;
    z-index: 1;
    width: 20%; 
  }

  .cloud_1{
    left: 17%;
    top: 20%;
  }

  .cloud_2{
    left: 50%;
    top: 40%;
  }

  .cloud_3{
    left: 60%;
    top: 10%;
  }
`;

export const Airplane = styled.div`
  position: absolute;
  top: 150px;
  z-index: 2;
  animation: ${airplane} 25s linear infinite;
  width: 10%;
  max-width: 100px;

  @media only screen and (max-width: 900px) {
    top: 120px;
  }

  @media only screen and (max-width: 600px) {
    top: 150px;
  }
 
`;

export const Info = styled.div`
  width: 100%;
  max-width: 400px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media only screen and (max-width: 900px) {
    top: 50%;
    padding: 30px;
  }

  @media only screen and (max-width: 600px) {
    top: auto;
    bottom: 30px;
    transform: translate(-50%, 0);
  }
`;

export const Title = styled.p`
  text-align: center;
  font-size: 38px;
  color: #1B99A4;
  font-weight: bold;

  @media only screen and (max-width: 600px) {
    font-size: 20px;
  }
`;

export const Button = styled.a`
  text-align: center;
  background: #28C976;
  border-radius: 30px;
  margin: 30px 60px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 5px;


  span{
    margin-left: 10px;
    font-weight: bold;
    font-size: 20px;
    color: #fff;

    @media only screen and (max-width: 600px) {
      font-size: 15px;
    }
  }

  

`;