import React, { useState } from "react"
import { Container } from './styles';

import './styles.js';
import './style.css';
function BlogPage() {
  
  return (
    <Container>
        <h1 id="blogTitle">Página em Desenvolvimento</h1>    
    </Container>
   

  );
}

export default BlogPage;