import React from 'react';
import { Container } from './styles';

import logo_abragames from 'src/images/logo_abragames.png';

function Abragames() {
  return (
    <Container>
      <img src={logo_abragames} />
    </Container>
  );
}

export default Abragames;