import React from 'react';

import { Container } from './styles';
import arraia from 'src/images/arraia.png';

export default function Arraia({ ...rest }) {
  return (
    <Container {...rest}>
      <img src={arraia} width="400px" />
    </Container>
  );
}
